<template>
  <div class="mt-5">
    <v-row class="mt-5">
      <!-- ordenes activas -->
      <v-card class="mx-auto m-2 div-dashboard" max-width="344" min-width="250">
        <v-card-text>
          <v-img max-height="110" style="margin: 0 auto" max-width="110" src="/yaqui/ordenes_activas_img.png"></v-img>
          <div class="text-center">Ordenes Activas</div>
          <p style="margin-bottom: -10px;" class="text-h4 text--primary text-center">{{ ordenesActivas }}</p>
        </v-card-text>
      </v-card>

      <!-- ordenes totales del día -->
      <v-card class="mx-auto m-2 div-dashboard" max-width="344" min-width="250">
        <v-card-text>
          <v-img max-height="110" style="margin: 0 auto" max-width="110"
            src="/yaqui/ordenes_totales_del_dia_img.png"></v-img>
          <div class="text-center">Ordenes Totales del día</div>
          <p style="margin-bottom: -10px;" class="text-h4 text--primary text-center">{{ ordenesTotalesDia }}</p>
        </v-card-text>
      </v-card>
      <!-- ganancias del día -->
      <v-card class="mx-auto m-2 div-dashboard" max-width="344" min-width="250">
        <v-card-text>
          <v-img max-height="110" style="margin: 0 auto" max-width="110" src="/yaqui/ganacia_del_dia_img.png"></v-img>
          <div class="text-center">Ganancias del día</div>
          <p style="margin-bottom: -10px;" class="text-h4 text--primary text-center">{{ gananciasDia }}</p>
        </v-card-text>
      </v-card>
      <!-- drivers conectados -->
      <v-card class="mx-auto m-2 div-dashboard" max-width="344" min-width="250">
        <v-card-text>
          <v-img max-height="110" style="margin: 0 auto" max-width="110"
            src="/yaqui/drivers_conectados_img.png"></v-img>
          <div class="text-center">Drivers conectados</div>
          <p style="margin-bottom: -10px;" class="text-h4 text--primary text-center">{{ driversConectados }}</p>
        </v-card-text>
      </v-card>
    </v-row>
    <v-row>
      <!-- drivers desconectados -->
      <v-card class="mx-auto m-2 div-dashboard" max-width="344" min-width="250">
        <v-card-text>
          <v-img max-height="110" style="margin: 0 auto" max-width="110"
            src="/yaqui/drivers_desconectados_img.png"></v-img>
          <div class="text-center">Drivers Desconectados</div>
          <p style="margin-bottom: -10px;" class="text-h4 text--primary text-center">{{ driversDesconectados }}</p>
        </v-card-text>
      </v-card>
      <!-- total clientes -->
      <v-card class="mx-auto m-2 div-dashboard" max-width="344" min-width="250">
        <v-card-text>
          <v-img max-height="110" style="margin: 0 auto" max-width="110" src="/yaqui/total_clientes_img.png"></v-img>
          <div class="text-center">Total clientes</div>
          <p style="margin-bottom: -10px;" class="text-h4 text--primary text-center">{{ totalClientes }}</p>
          <!-- <p>adjective</p> -->
          <!-- <div class="text--primary">
            well meaning and kindly.<br />
            "a benevolent smile"
          </div> -->
        </v-card-text>
        <!-- <v-card-actions>
          <v-btn text color="deep-purple accent-4"> Learn More </v-btn>
        </v-card-actions> -->
      </v-card>
      <!-- notificaciones -->
      <v-card class="mx-auto m-2 div-dashboard" max-width="344" min-width="250">
        <v-card-text>
          <v-img max-height="110" style="margin: 0 auto" max-width="110" src="/yaqui/notificaciones_img.png"></v-img>
          <div class="text-center">Notificaciones</div>
          <p style="margin-bottom: -10px;" class="text-h4 text--primary text-center">{{ notificaciones }}</p>
          <!-- <p>adjective</p> -->
          <!-- <div class="text--primary">
            well meaning and kindly.<br />
            "a benevolent smile"
          </div> -->
        </v-card-text>
        <!-- <v-card-actions>
          <v-btn text color="deep-purple accent-4"> Learn More </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-row>

    <!-- <v-row>
      <v-col cols="12" sm="6">
        <crm-activity-timeline></crm-activity-timeline>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <crm-weekly-sales></crm-weekly-sales>
      </v-col>

      <v-col cols="12" md="2">
        <v-row>
          <v-col cols="12" sm="6" md="12">
            <statistics-card-area-chart
              :stat-title="growthAreaChart.statTitle"
              :statistics="growthAreaChart.statistics"
              :chart-series="growthAreaChart.series"
              chart-height="100"
            ></statistics-card-area-chart>
          </v-col>
          <v-col cols="12" sm="6" md="12">
            <statistics-card-vertical
              :change="newProjectOptions.change"
              :color="newProjectOptions.color"
              :icon="newProjectOptions.icon"
              :statistics="newProjectOptions.statistics"
              :stat-title="newProjectOptions.statTitle"
              :subtitle="newProjectOptions.subtitle"
            ></statistics-card-vertical>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" sm="6" md="4">
        <crm-card-upgrade-plan></crm-card-upgrade-plan>
      </v-col>

      <v-col cols="12" md="4" sm="6">
        <crm-card-meeting-schedule></crm-card-meeting-schedule>
      </v-col>

      <v-col cols="12" md="4" sm="6">
        <crm-card-developer-meetup></crm-card-developer-meetup>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
// import StatisticsCardVertical from '@/@core/components/statistics-card/StatisticsCardVertical.vue'
// import StatisticsCardAreaChart from '@core/components/statistics-card/StatisticsCardAreaChart.vue'
// import StatisticsCardWithImages from '@core/components/statistics-card/StatisticsCardWithImages.vue'

// icons
// import { mdiLabelVariantOutline } from '@mdi/js'

// demos
// import CrmActivityTimeline from './CrmActivityTimeline.vue'
// import CrmCardDeveloperMeetup from './CrmCardDeveloperMeetup.vue'
// import CrmCardMeetingSchedule from './CrmCardMeetingSchedule.vue'
// import CrmCardUpgradePlan from './CrmCardUpgradePlan.vue'
// import CrmRevenueReport from './CrmRevenueReport.vue'
// import CrmSalesOverview from './CrmSalesOverview.vue'
// import CrmStatisticsCard from './CrmStatisticsCard.vue'
// import CrmTotalSales from './CrmTotalSales.vue'
// import CrmWeeklySales from './CrmWeeklySales.vue'

export default {
  // components: {
  //   StatisticsCardWithImages,
  //   StatisticsCardAreaChart,
  //   StatisticsCardVertical,

  //   CrmStatisticsCard,
  //   CrmTotalSales,
  //   CrmRevenueReport,
  //   CrmSalesOverview,
  //   CrmActivityTimeline,
  //   CrmWeeklySales,
  //   CrmCardUpgradePlan,
  //   CrmCardMeetingSchedule,
  //   CrmCardDeveloperMeetup,
  // },
  // setup() {
  //   // card with images
  //   const ratingsOptions = {
  //     statTitle: 'Ratings',
  //     statistics: '13k',
  //     change: '+38%',
  //     chipText: 'Year of 2021',
  //     chipColor: 'primary',
  //     avatar: require('@/assets/images/avatars/9.png'),
  //     avatarWidth: '111',
  //   }
  //   const sessionsOptions = {
  //     statTitle: 'Sessions',
  //     statistics: '24.5k',
  //     change: '-22%',
  //     chipText: 'Last Week',
  //     chipColor: 'secondary',
  //     avatar: require('@/assets/images/avatars/10.png'),
  //     avatarWidth: '86',
  //   }

  //   const growthAreaChart = {
  //     statTitle: 'Total Growth',
  //     statistics: '42.5k',
  //     series: [
  //       {
  //         name: 'Subscribers',
  //         data: [28, 40, 36, 52, 38, 60, 55],
  //       },
  //     ],
  //   }

  //   // vertical card options
  //   const newProjectOptions = {
  //     statTitle: 'New Project',
  //     icon: mdiLabelVariantOutline,
  //     color: 'primary',
  //     subtitle: 'Yearly Project',
  //     statistics: '862',
  //     change: '-18%',
  //   }

  //   return {
  //     ratingsOptions,
  //     sessionsOptions,
  //     growthAreaChart,
  //     newProjectOptions,
  //   }
  // },
  data() {
    return {
      items: {},
      ordenesActivas: 0,
      ordenesTotalesDia: 0,
      gananciasDia: 0,
      driversConectados: 0,
      driversDesconectados: 0,
      totalClientes: 0,
      notificaciones: 0,
    }
  },
  async mounted() {
    this.ordenesActivas = await this.getData('activas')
    this.ordenesTotalesDia = await this.getData('totalordenesdia')
    this.gananciasDia = await this.getData('gananciasDia')
    this.driversConectados = await this.getData('driversConectados')
    this.driversDesconectados = await this.getData('driversDesconectados')
    this.totalClientes = await this.getData('totalClientes')
    this.notificaciones = 0 //await this.getData('totalClientes')
  },
  methods: {
    async getData(it) {
      try {
        let res = await this.$store.dispatch('get', { path: 'reportes/resumen/' + it })
        console.log('data ->', res)
        //this.items = res[0]
        //this.totalItems = this.items.length
        return res
      } catch (error) {
        //this.items = []
        console.error('error', error)
        return 0
      }
    },
  },
}
</script>
